import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";
import book1 from '../../images/auf-sexuellegesundheit1.png'

const AUFSexuelleGesundheit = () => {
  return (
    <div className='auf-sexuellegesundheit'>
    <h1 className='section-title title-arbeits'>Arbeits- und Forschungsfelder <h2>Sexuelle Gesundheit</h2></h1>
      <Breadcrumb>
      <span className='me-2 fw-bold breadcrumb-section'>Arbeits- und Forschungsfelder / </span>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sozialpadagogik" }}>Sozialpädagogik</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sexualpadagogik" }}>
          Sexualpädagogik
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sexualkultur" }}>
        Sexualkultur
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
        Sexuelle Gesundheit
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="card new-display">
          <h2 className='card-subtitle'>Sexuelle Gesundheit</h2>
          <div className="aktuelles-description">Während in Deutschland das Konstrukt „Sexuelle Gesundheit“ gemäß der kritischen Sexualwissenschaft noch sehr mit staatlicher Biopolitik im Nationalsozialismus assoziiert wird, wurden im internationalen Kontext viele Anstrengungen unternommen, sexuelle Gesundheit mit sexuellen Rechten und sexuellem Wohlbefinden zusammenzudenken und zu einem politischen Konzept zu verbinden (https://worldsexualhealth.net/).  Dem entsprechen zentrale Forschungsergebnisse aus meinen empirischen Analysen zur Situation schwul-lesbischer Lebensweisen in Schleswig-Holstein (Studie für das Familienministerium SH (Sielert 2000) und einer Expertise zur Situation schwul-lesbischer Jugendliche in Deutschland, die ich 2014 zusammen mit Stefan Timmermanns verfasste und die zur Vorbereitung eines bundesweiten Forschungsprojekts des Deutschen Jugendinstituts diente (Sielert/Timmermanns 2014) </div>

          <div className='auf-image-group my-4'>
            <img className='auf-image me-5' src={book1} alt="arbeitshilfe buch" style={{height: '250px'}}/>
          </div>

          <div className="aktuelles-description">2019 gab der Verband privater Krankenversicherung (PKV) dem medizinischen Zentrum in Bochum WIR („Walk in Ruhr“) den Auftrag, mit Hilfe einer <span className='fw-bold'>Expertise</span> die <span className='fw-bold'>Zusammenhänge zwischen der sexuellen Gesundheit vulnerabler Bevölkerungsgruppen und deren Diskriminierung sowie mangelnden Zugängen zur medizinischen Versorgung</span> zu erforschen.  Die Ergebnisse der Expertise – in deren Schlussphase ich mitarbeiten konnte – führten 2021 zur Bewilligung eines <span className='fw-bold'>fünfjährigen Modellvorhabens zur Gesundheitsförderung und Prävention</span> in den Bereichen der Wohnungslosenhilfe und jungen Migrant*innen in Wohneinrichtungen, jungen Menschen mit Hörminderung, mit trans* und nicht-binärer Identität, im offenen Strafvollzug, mit Substanzkonsum in Verbindung mit Sex (Chamsex) Sexarbeitende und ihre Kund*innen sowie Menschen mit Swinger-Lifestyle. Die einzelnen Angebote werden von einer interdisziplinären Arbeitsgruppe (Sexualmedizin- und -pädagogik, Psychologie, Kommunikationswissenschaft, Sexualwissenschaft, Organisationsentwicklung, Digital Education, Healthadvising) gemeinsam mit den jeweiligen Organisationen und den jungen Communitys erarbeitet, in die Praxis der Dienstleistungsorganisationen implementiert und von einem Team der Universität Bielefeld (Fakultät für Gesundheitswissenschaften) evaluiert. </div>

          <div className="aktuelles-description"><span className='fw-bold'>Als externer Berater</span>  bin ich mit meinen inzwischen langjährigen Erfahrungen in der Erziehungs- und Sexualwissenschaft, der Sexuellen Bildung und Organisationsentwicklung, der Forschung und Evaluation an dem gesamten Modellvorhaben maßgeblich beteiligt. Faszinierend ist die Möglichkeit, viele internationale Erfahrungen im Bereich der Sexuellen Gesundheit auch in Deutschland umzusetzen (Wirtz/Brockmeyer/Sielert 2022).</div>

      </div>
  </div>
  )
}

export default AUFSexuelleGesundheit