import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";
import book1 from '../../images/auf-sozial-book1.png'
import book2 from '../../images/auf-sozial-book2.png'
import book3 from '../../images/auf-sozial-book3.png'
import book4 from '../../images/auf-sozial-book4.png'
import book5 from '../../images/auf-sozial-book5.png'
import book6 from '../../images/auf-sozial-book6.png'
import book7 from '../../images/auf-sozial-book7.png'
import book8 from '../../images/auf-sozial-book8.png'
const AUFSozialpadagogik = () => {
  return (
    <div className='auf-sozialpadagogik'>
      <h1 className='section-title title-arbeits'>
        Arbeits- und Forschungsfelder <h2>Sozialpädagogik</h2>
      </h1>
      <Breadcrumb>
        <span className='me-2 fw-bold breadcrumb-section'>Arbeits- und Forschungsfelder / </span>
        <Breadcrumb.Item active>Sozialpädagogik</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sexualpadagogik" }}>
            Sexualpädagogik
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sexualkultur" }}>
          Sexualkultur
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sexuelle-gesundheit" }}>
          Sexuelle Gesundheit
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="card new-display">
      <h2 className='card-subtitle'>Sozialpädagogik</h2>
          <div className="aktuelles-description">Mit der Einführung des erziehungswissenschaftlichen Diplomstudiengangs stieg die Chance der außerschulischen Handlungsfelder, insbesondere der Sozialpädagogik, sich zu professionalisieren. Meiner Praxis neben dem Studium folgend, leitete ich von 1975-1977 das von der Hochschule finanzierte Forschungsprojekt <span className='fw-bold'>"Curriculum der Aus- und Fortbildung von Mitarbeitern in der Jugendarbeit“</span> und beteiligte mich nach der Promotion an diversen Aktionsforschungsprojekten zur Innovation kommunaler Jugendhilfe in Dortmund.</div>
          
          <div className='auf-image-group my-5'>
            <img className='auf-image me-0 me-md-5' src={book1} alt="arbeitshilfe buch" />
            <img className='auf-image' src={book2} alt="arbeitshilfe buch" />
          </div>

          <div className="aktuelles-description">Angeregt von der Kollegin und damaligen Familien- und Jugendministerin Prof. Dr. Rita Süßmuth und finanziert durch den DAAD lehrte ich 1979 als Gastdozent für „Soziologie der Sozialen Arbeit“ an der Freien Universität von Amsterdam. Mit Hilfe des dort angesiedelten Forschungsprojekts über <span className='fw-bold'>"Dezentralisation im System der niederländischen Sozialarbeit"</span> habilitierte ich über den Umgang der niederländischen politisch-administrativen Systeme mit Basisbewegungen in der Jugendhilfe und leitete das vom BMBFS von 1980-1982 finanzierten Forschungsprojekt über <span className='fw-bold'>„alternative Jugendhilfe in den Niederlanden“</span>. Wesentliche Impulse wurden bei der Reform der Jugendhilfe in Deutschland berücksichtigt. </div>

          <div className='auf-image-group my-5'>
            <img className='auf-image  me-0 me-md-5' src={book3} alt="arbeitshilfe buch" />
            <img className='auf-image' src={book4} alt="arbeitshilfe buch" />
            
          </div>

          <div className="aktuelles-description">Geschlechterforschung und entsprechende Förderprogramme bezogen sich in den 1980er Jahren bundesweit in erster Linie auf die Mädchen und Frauenforschung. Mit Projekten und Veröffentlichungen konnte ich dazu beitragen, dass sich parallel zur Mädchenbildung bei der <span className='fw-bold'>Erziehung und Bildung von Jungen und Männern</span> die Flexibilisierung der Geschlechterrolle in eine emanzipatorische Richtung bewegt. Schnell spielte dann auch das Thema Sexualität eine Rolle.</div>

          <div className='auf-image-group my-5'>
            <img className='auf-image  me-0 me-md-5' src={book5} alt="arbeitshilfe buch" />
            <img className='auf-image' src={book6} alt="arbeitshilfe buch" />
            <p className='mt-2'><a rel='noopener noreferrer' target='_blank' className='source source-aktuelles' href='https://www.beltz.de/fachmedien/paedagogik/produkte/details/3261-jungenarbeit.html'>https://www.beltz.de/fachmedien/paedagogik/produkte/details/3261-jungenarbeit.html</a></p>
          </div>

          <div className="aktuelles-description">Eingebettet wurde diese Reform in das Konzept einer umfassenden Pädagogik der Vielfalt, die Mitte der 1990er Jahre von Annedore Prengel entwickelt wurde. Von 2000-2002 förderte das Sozialministerium SH das <span className='fw-bold'>Handlungsforschungsprojekt "difference troubles" - Wirksamkeitsdialoge von Antidiskriminierungsmaßnahmen in Ländern des Ostseeraums</span>. Als Ergebnis erschien das Training <span className='fw-bold'>"Sozialpädagogik der Vielfalt"</span> für universitäre Ausbildungsstätten, das im Institut für Pädagogik zum festen Curriculumbaustein in der Pädagog*innenausbildung gehört. </div>

          <div className='auf-image-group my-5'>
            <img className='auf-image  me-0 me-md-5' src={book7} alt="arbeitshilfe buch" />
            <img className='auf-image' src={book8} alt="arbeitshilfe buch" />
          </div>

          <div className="aktuelles-description">Parallel dazu leitete ich von 1998 bis 2011 verschiedene Evaluationsprojekte im Auftrag der zuständigen Landessministerien, so zum Modellprojekt <span className='fw-bold'>„Sport gegen Gewalt“</span> (1998-1999), <span className='fw-bold'>"Jugendhilfe und Schule im Sozialraum Kiel-Gaarden"</span> (2005-2007) <span className='fw-bold'>"Schulsozialarbeit im Flensburger Norden"</span>(2008-2009) und als Ergebnis die <span className='fw-bold'>„Entwicklung eines Konzepts zur Selbstevaluation der Ganztagsschulen in Schleswig-Holstein“</span> (2009).</div>

        </div>

  </div>
  )
}

export default AUFSozialpadagogik