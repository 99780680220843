const Footer = () => {
  const today = new Date();
  return (
    <div className="footer">

            <h5>Prof. Dr. Uwe Sielert | Erziehungswissenschaftler und Sexualpädagoge</h5>
            <p>&copy; {today.getFullYear()}</p>

    </div>
  )
}

export default Footer