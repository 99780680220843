import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";

const Mitgliedschaften = () => {
  return (
    <div className='mitgliedschaften'>
      <h1 className='section-title breadcrumb-section'>Mitgliedschaften</h1>
      <Breadcrumb>
          <span className='me-2 fw-bold breadcrumb-section'>Uwe Sielert / </span>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/ausbildung" }}>
          Ausbildung
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/berufstatigkeit" }}>Berufstätigkeit</Breadcrumb.Item>
          <Breadcrumb.Item active>
          Mitgliedschaften
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>2018 – 2021</span></div>
          <div className="card-thema">Vorsitzender des Fachbeirats im BMBF der Förderlinie Bildungsforschung „Forschung zu sexualisierter Gewalt“</div>
        </div>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>2012 – 2014</span></div>
          <div className="card-thema">Mitglied der Ad-hoc-Kommission der Evangelischen Kirche Deutschlands zur Erarbeitung einer Denkschrift zur Sexualethik</div>
        </div>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>Seit 1989</span></div>
          <div className="card-thema">Gründungsmitglied und Wissenschaftlicher Beirat des Instituts für Sexualpädagogik (ISP)</div>
        </div>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>1998 – 2020</span></div>
          <div className="card-thema">Vorstandsmitglied der Gesellschaft für Sexualpädagogik (gsp)</div>
        </div>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>Seit 2015</span></div>
          <div className="card-thema">Mitglied (bis 2018 Vorstandsmitglied) der Deutschen STI-Gesellschaft (DSTIG)</div>
        </div>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>Seit 1992</span></div>
          <div className="card-thema">Mitglied der Deutschen Gesellschaft für Erziehungswissenschaft (DGfE);</div>
          <div className="card-thema"><span className='fw-bold'>2012 – 2013</span> Mitarbeit in der Ad-hoc-Kommission "Sexualität, Gewalt und Pädagogik"</div>
        </div>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>2011 – 2013</span></div>
          <div className="card-thema">AG III “Forschung und Ausbildung” des Runden Tischs im BMBF zur Prävention des sexuellen Missbrauchs</div>
        </div>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>2011 – 2012</span></div>
          <div className="card-thema">Beirat zu den Studien des Deutschen Jugendinstituts (DJI) im Auftrag des Unabhängigen Beauftragten der Bundesregierung zum Thema "Sexueller Missbrauch"</div>
        </div>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>1995 – 1997</span></div>
          <div className="card-thema">Redaktionsmitglied der Zeitschrift "Pro Jugend" Schleswig-Holstein</div>
        </div>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>1991 – 1997</span></div>
          <div className="card-thema">Redaktionsmitglied der Fachzeitschrift "Themenzentrierte Interaktion"</div>
        </div>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>1989</span></div>
          <div className="card-thema">Mitglied des "Nationalen AIDS - Beirats" der Bundesregierung</div>
        </div>

        <div className="card">
          <div className="card-year"><span className='fw-bold'>1977 – 1979</span></div>
          <div className="card-thema">Mitglied im Ständigen Ausschuss für Beratung und Seelsorge des Deutschen Evangelischen Kirchentags</div>
        </div>

    </div>
  )
}

export default Mitgliedschaften