import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";
import book1 from '../../images/auf-sexual-book1.png'
import book2 from '../../images/auf-sexual-book2.png'
import book3 from '../../images/auf-sexual-book3.png'
import book4 from '../../images/auf-sexual-book4.png'
import book5 from '../../images/auf-sexual-book5.png'
import book6 from '../../images/auf-sexual-book6.png'
import book7 from '../../images/auf-sexual-book7.png'
import book8 from '../../images/auf-sexual-book8.png'
import book9 from '../../images/auf-sexual-book9.png'
import medicalLogo from '../../images/medical.png'

const AUFSexualpadagogik = () => {
  return (
    <div className='auf-sexualpadagogik'>
    <h1 className='section-title title-arbeits breadcrumb-section'>Arbeits- und Forschungsfelder <h2>Sexualpädagogik</h2></h1>
      <Breadcrumb>
      <span className='me-2 ps-3 fw-bold breadcrumb-section'>Arbeits- und Forschungsfelder / </span>
        <Breadcrumb.Item className="ps-1" linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sozialpadagogik" }}>Sozialpädagogik</Breadcrumb.Item>
        <Breadcrumb.Item active>
        Sexualpädagogik
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sexualkultur" }}>
        Sexualkultur
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sexuelle-gesundheit" }}>
        Sexuelle Gesundheit
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="card new-display">
          <h2 className='card-subtitle'>Sexualpädagogik</h2>
          <div className="aktuelles-description">Die Bündelung wesentlicher Quellen moderner, der sexuellen Selbstbestimmung verpflichteten Sexualpädagogik in Deutschland erfolgte Mitte der 1980er Jahre im Institut für Sozialpädagogik der Universität Dortmund. Der Bundesjugend- und Sozialminister Heiner Geißler vollzog 1982 die „geistig-moralische Wende“ der Kohl-Regierung im sexualmoralischen Bereich durch das Einstampfen der bis dahin von der BZgA vertriebenen Materialien zur emanzipatorischen Sexualerziehung „Betrifft: Sexualität“ und gab dem Team der Professoren Siegfried Keil und Uwe Sielert den Auftrag, die Sexualpädagogik auf wertbezogen „neue Füße“ zu stellen. Von 1984 – 1988 rekapitulierte ein interdisziplinäres Projektteam den sexualpädagogischen Forschungsstand, bildete eine Diskusgruppe aus allen in Deutschland zum Thema arbeitenden Kolleg*innen, erarbeitete und erprobte zusammen mit Bildungsreferent*innen aus den wichtigsten Jugendverbänden ein umfangreiches <span className='fw-bold'>Curriculum zur sexuellen Bildung für die außerschulische Jugendarbeit</span>. Trotz ministerieller Unterstützung durch Rita Süßmuth, die Heiner Geißler im Amt abgelöst hatte, blockierte die CDU-Administration die Implementation des Curriculums durch die BZgA, überlies aber der Dortmunder Projektgruppe die Weiterarbeit mit den Materialien.</div>

          <div className='auf-image-group my-5'>
            <img className='auf-image me-0 me-md-5' src={book1} alt="arbeitshilfe buch" style={{height: '180px'}}/>
            <p className='mt-2'><a rel='noopener noreferrer' target='_blank' className='source source-aktuelles' href='https://antiquarisch.de/giaq/article/21140390-sielert-uwe--keil-siegfried-sexualpdagogische-materialien-fr-die-jugendarbeit'>https://antiquarisch.de/giaq/article/21140390-sielert-uwe--keil-siegfried-sexualpdagogische-materialien-fr-die-jugendarbeit</a></p>
          </div>

          <div className="aktuelles-description">Neben der Veröffentlichung zentraler didaktischer Inhalte bei Beltz (Sielert u.a. 1993) bildete das Curriculum die Basis für das <span className='fw-bold'>Dortmunder Institut für Sexualpädagogik (isp)</span>, des zentralen Fach- und Fortbildungsinstituts für Sexualpädagog*innen in Deutschland (<span><a rel='noopener noreferrer' target='_blank' className='link-text ' href='https://www.isp-sexualpaedagogik.org/'>https://www.isp-sexualpaedagogik.org/</a></span>). Aus dem Institut ging 1990 die <span className='fw-bold'>Gesellschaft für Sexualpädagogik (gsp) </span>hervor, ein Zusammenschluss für professionell Tätige des Erziehungs- und Bildungs-, des Sozial- und Gesundheitsbereichs, welche Selbstbestimmung und Selbstverantwortung in den Bereichen Sexualität und Beziehungsleben aller Menschen anerkennen und fördern (<span><a rel='noopener noreferrer' target='_blank' className='link-text ' href='www.gsp-ev.de'>www.gsp-ev.de</a></span>)</div>

          <div className="aktuelles-description">Nach meiner Berufung zum Professor für Pädagogik nach Kiel setzte ich die curriculare Bildungsarbeit zur Sexualpädagogik fort und leitete von 1994 – 1997 das <span className='fw-bold'>„Bund-Länder-Modellprojekt Sexualpädagogik in der universitären Ausbildung- Erarbeitung eines Ausbildungscurriculums“</span> sowie von 1997-1999 das Modellprojekt <span className='fw-bold'>"Situationsanalyse zur Sexualpädagogik in den Fachschulen für Sozialpädagogik und Berufsfachschulen in Schleswig-Holstein“</span>, gefördert von der BZgA Köln. Es entstand die <span className='fw-bold'>Abteilung für Sexualpädagogik im Institut für Pädagogik</span> mit zahlreichen Studienangeboten für die Lehramts- und Hauptfachpädagog*innenausbildung. </div>

          <div className='auf-image-group my-5'>
            <img className='auf-image me-0 me-md-5' src={book2} alt="arbeitshilfe buch" />
            <img className='auf-image' src={book3} alt="arbeitshilfe buch" />
          </div>

          <div className="aktuelles-description">Standardwerke für die Ausbildung wurden das 2012 erschienene Arbeitsbuch für die Ausbildung in Fachschulen für Sozialpädagogik sowie die 2005 erstmals erschienene und 2015 völlig überarbeitete Einführung in die Sexualpädagogik und die „Gelebte Geschichte der Sexualpädagogik“, an der über 50 Sexualpädagog*innen aus 5 Generationen mitgearbeitet haben. </div>

          <div className='auf-image-group my-5'>
            <img className='auf-image  me-0 me-md-5' src={book4} alt="arbeitshilfe buch" />
            <img className='auf-image  me-0 me-md-5' src={book5} alt="arbeitshilfe buch" />
            <img className='auf-image' src={book6} alt="arbeitshilfe buch" />
            <p className='mt-2'><a rel='noopener noreferrer' target='_blank' className='source source-aktuelles' href='https://www.beltz.de/fachmedien/paedagogik/produkte/details/15261-einfuehrung-in-die-sexualpaedagogik.html'>https://www.beltz.de/fachmedien/paedagogik/produkte/details/15261-einfuehrung-in-die-sexualpaedagogik.html</a></p>
          </div>

          <div className="aktuelles-description">Hinzu kam die Expertise für die BZgA zu „E-Learning, eine erste Einführung in  die Sexualpädagogik in der Jugendhilfe und  sowie eine Hochschuldidaktik der Sexualpädagogik:  </div>

          <div className='auf-image-group my-5'>
            <img className='auf-image  me-0 me-md-5' src={book7} alt="arbeitshilfe buch" />
            <img className='auf-image  me-0 me-md-5' src={book8} alt="arbeitshilfe buch" />
            <img className='auf-image' src={book9} alt="arbeitshilfe buch" />
            <p className='mt-2'><a rel='noopener noreferrer' target='_blank' className='source source-aktuelles' href='https://www.amazon.de/Sexualp%C3%A4dagogik-lehren-Uwe-Sielert/dp/340755835X'>https://www.amazon.de/Sexualp%C3%A4dagogik-lehren-Uwe-Sielert/dp/340755835X</a></p>
          </div>

          <div className="aktuelles-description">Nach dem Ausscheiden aus dem aktiven Dienst als Universitätsprofessor an der Christian-Albrechts-Universität zu Kiel arbeitete ich am Aufbau eines <span className='fw-bold'>Studiengangs „Sexualwissenschaft“</span> mit den Schwerpunkten Sexualpädagogik und Sexualberatung an der <span className='fw-bold'>Medical School Hamburg (MSH)</span>, der inzwischen auch von der <span className='fw-bold'>Medical School Berlin (MSB)</span> angeboten wird.</div>

          <div className='auf-image-group my-4'>
            <img className='auf-image  me-0 md-me-5' src={medicalLogo} alt="arbeitshilfe buch" style={{height: '100px', borderRadius:'5px'}} />
            <p className='mt-2'><a rel='noopener noreferrer' target='_blank' className='source source-aktuelles' href='https://www.arts-and-social-change.de/master/sexualwissenschaft/'>https://www.arts-and-social-change.de/master/sexualwissenschaft/</a></p>
          </div>

        </div>

  </div>
  )
}

export default AUFSexualpadagogik