
const Rezensionen = () => {
  return (
    <div className='aktuelles'>
         <h1 className='section-title title-aktuelles'>Rezensionen</h1>
         <div className="card">
            <div className="card-thema fw-bold">
                Thomas Haenel: Außergewöhnliche Facetten der Sexualität. Psychologische, gesellschaftliche und kulturelle Phänomene. <span className="text-decoration-underline purple"><a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.frank-timme.de/'>Frank & Timme</a></span> (Berlin) 2021. 157 Seiten.  24,80 EUR

                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.socialnet.de/rezensionen/29211.php'>https://www.socialnet.de/rezensionen/29211.php</a>
                </div>
            </div>

            <div className="card-thema fw-bold mt-5">
            Heilmann, Joachim u.a. (Hrsg.): Unheimlich und verlockend. Zum pädagogischen Umgang mit Sexualität von Kindern und Jugendlichen
            Psychosozial-Verlag GmbH & Co. KG (Gießen) 2017. ISBN 978-3-8379-2719-1

                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.socialnet.de/rezensionen/23630.php'>https://www.socialnet.de/rezensionen/23630.php</a>
                </div>
            </div>

            <div className="card-thema fw-bold mt-5">
            Plaß, Michael: Homosexualitäten* und Heteronormativität in der Pädagogik. Eine
            Diskursanalyse Julius Klinkhardt Verlagsbuchhandlung (Bad Heilbrunn) 2017. ISBN 978-3-
            7815-2169-8

                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.socialnet.de/rezensionen/22885.php'>https://www.socialnet.de/rezensionen/22885.php</a>
                </div>
            </div>

            <div className="card-thema fw-bold mt-5">
            Martin, Beate u.a.: Sexualität und Schule. Verlag W. Kohlhammer (Stuttgart) 2017. ISBN 978-3-17-032471- 8 28,00 EUR
                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.socialnet.de/rezensionen/22344.php'>https://www.socialnet.de/rezensionen/22344.php</a>
                </div>
            </div>

            <div className="card-thema fw-bold mt-5">
            Karla Verlinden: Sexualität und Beziehungen bei den &quot;68ern&quot;.
            Erinnerungen ehemaliger Protagonisten und Protagonistinnen.
            <div><a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.transcript-verlag.de/'>transcript</a> (Bielefeld) 2015. 465 Seiten. ISBN 978-3 8376-2974-3 € 29,99</div>
                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.socialnet.de/rezensionen/18430.php'>https://www.socialnet.de/rezensionen/18430.php</a>
                </div>
            </div>


            <div className="card-thema fw-bold mt-5">
            Dörnemann, Holger / Leimgruber, Stephan (2022):
            <br/>Sexuelle Bildung aus christlicher Perspektive. Für Erziehung, Pädagogik und
            Gemeindepraxis. Paderborn: Bonifatius
            </div>

            <div className="card-thema fw-bold mt-5">
            Ralf Pampel. Wir reden zu wenig! Angebote zur sexuellen Bildung Erwachsener. Gießen:
            Psychosozial 2019. 121 Seiten, EUR 16,90
                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.thieme-connect.com/products/ejournals/abstract/10.1055/a-1476-9956'>https://www.thieme-connect.com/products/ejournals/abstract/10.1055/a-1476-9956</a>
                </div>
            </div>                                    

            <div className="card-thema fw-bold mt-5">
            Peer Briken, Hrsg. Perspektiven der Sexualforschung. Gießen: Psychosozial 2019 (Reihe:
Beiträge zur Sexualforschung, Bd. 108). 548 Seiten, EUR 54,90 [ <span><a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.thieme-connect.com/products/ejournals/abstract/10.1055/a-1669-7387'>1</a></span> ]
                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='Thieme E-Journals - Zeitschrift für Sexualforschung / Abstract (thieme-connect.com)'>Thieme E-Journals - Zeitschrift für Sexualforschung / Abstract (thieme-connect.com)</a>
                </div>
            </div>
 
            <div className="card-thema fw-bold mt-5">
            Karla Verlinden: Sexualität und Beziehungen bei den &quot;68ern&quot;. Erinnerungen ehemaliger
Protagonisten und Protagonistinnen.             
<div><a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.transcript-verlag.de/'>transcript</a> (Bielefeld) 2015. 465 Seiten. ISBN 978-3-
8376-2974-3 . D: 29,99 EUR</div>
                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.socialnet.de/rezensionen/18430.php'>https://www.socialnet.de/rezensionen/18430.php</a>
                </div>
            </div>

            <div className="card-thema fw-bold mt-5">
            Josef Christian Aigner: Vorsicht Sexualität! Sexualität in Psychotherapie, Beratung und
Pädagogik ; eine integrative Perspektive. <span><a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.kohlhammer.de/'>Verlag W. Kohlhammer</a></span> (Stuttgart) 2013.
233 Seiten.
                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.socialnet.de/rezensionen/15044.php'>https://www.socialnet.de/rezensionen/15044.php</a>
                </div>
            </div>


            <div className="card-thema fw-bold mt-5">
            Jeffrey Weeks: Sexuelle Gleichberechtigung. Gender, Sexualität und homosexuelle
Emanzipation in Europa. <span><a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.wallstein-verlag.de/'>Wallstein Verlag</a></span> (Göttingen) 2014. 59 Seiten.
ISBN 978-3-8353 1324-8 : 9,90 EUR,
                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.socialnet.de/rezensionen/16779.php'>https://www.socialnet.de/rezensionen/16779.php</a>
                </div>
            </div>

            <div className="card-thema fw-bold mt-5">
            Michael Plaß: Homosexualitäten* und Heteronormativität in der Pädagogik. Eine
Diskursanalyse. <span><a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.klinkhardt.de/'>Julius Klinkhardt Verlagsbuchhandlung</a></span> (Bad Heilbrunn) 2017. 200 Seiten.
ISBN 978-3-7815-2169-8. D: 39,00 EUR
                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.socialnet.de/rezensionen/22885.php'>https://www.socialnet.de/rezensionen/22885.php</a>
                </div>
            </div>

            <div className="card-thema fw-bold mt-5">
                Christian Niemeyer: Sozialpädagogik als Sexualpädagogik. Beiträge zu einer not-
                wendigen Neuorientierung des Faches als Lehrbuch. Weinheim/Basel: BeltzJuventa 2019,
                462 S., 39,95 €
                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.neue-praxis-
shop.de/WebRoot/Store9/Shops/64251991/5DE3/9683/FEBC/CF40/1EC4/0A0C/6D0B/8906
/slr19-2-vorschau.pdf'>https://www.neue-praxis-
shop.de/WebRoot/Store9/Shops/64251991/5DE3/9683/FEBC/CF40/1EC4/0A0C/6D0B/8906
/slr19-2-vorschau.pdf</a>
                </div>
            </div>

            <div className="card-thema fw-bold mt-5">
            Jens Clausen, Frank Herrath(Hrsg.): Sexualität leben ohne Behinderung. Das
            Menschenrecht auf sexuelle Selbstbestimmung. <span><a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.kohlhammer.de/'>Kohlhammer</a></span> (Stuttgart) 2013. 308 Seiten.
            ISBN 978-3-17-021906-9. 34,90€
                <div>
                    <a rel='noopener noreferrer' target='_blank' className='purple ' href='https://www.socialnet.de/rezensionen/14791.php'>https://www.socialnet.de/rezensionen/14791.php</a>
                </div>
            </div>
            

         </div>
    </div>
  )
}

export default Rezensionen