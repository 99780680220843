import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";

const Ausbildung = () => {
  return (
    <div className="ausbildung">
      
        <h1 className='section-title'>Ausbildung</h1>
        <Breadcrumb>
        <span className='me-2 fw-bold breadcrumb-section'>Uwe Sielert / </span>
          <Breadcrumb.Item active>Ausbildung</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/berufstatigkeit" }}>
          Berufstätigkeit
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/mitgliedschaften" }}>
          Mitgliedschaften
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="card">
          <div className="card-year">1982</div>
          <div className="card-habilitation"><span className='fw-bold'>Habilitation:</span> Lehrbefugnis für Sozialpädagogik</div>
          <div className="card-thema"><span className='fw-bold'>Thema der Habilitationsschrift:</span> "Sozialpädagogik/Sozialarbeit in den Niederlanden - Eine auslandspädagogische Studie zur Vergesellschaftung und Basisdemokratisierung sozialer Hilfen in westlichen Industriegesellschaften"</div>
        </div>

        <div className="card">
          <div className="card-year">1972 – 1980</div>
          <div className="card-thema"> berufsbegleitende <span className='fw-bold'>Ausbildung in der Themenzentrierten Interaktion</span> nach Ruth Cohn bei WILL - International (Workshop Institute for Living-Learning): Diplom als TZI-Gruppenleiter</div>
        </div>

        <div className="card">
          <div className="card-year">1977</div>
          <div className="card-thema"> Promotion <span className='fw-bold'>zum Doktor der Pädagogik</span></div>
          <div className="card-thema"><span className='fw-bold'>Thema der Dissertation:</span>"Mitarbeiter in der außerschulischen Jugendarbeit - Eine Situationsanalyse zur Erhebung curricularer Planungsdaten für die Aus- u. Fortbildung"</div>
        </div>

        <div className="card">
          <div className="card-year">1974</div>
          <div className="card-thema">Diplom in Erziehungswissenschaft, Schwerpunkte Erwachsenenbildung und Sozialpädagogik</div>
        </div>

    </div>
  )
}

export default Ausbildung