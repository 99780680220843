import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";

const PSexuelleGesundheit = () => {
  return (
    <div className='p-sexualkultur'>
    <h1 className='section-title title-publikationen'>Publikationen <h2>Sexuelle Gesundheit</h2></h1>
      <Breadcrumb>
      <span className='me-2 fw-bold breadcrumb-section'>Publikationen / </span>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/publikationen/sozialpadagogik" }}>Sozialpädagogik</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/publikationen/sexualpadagogik" }}>
          Sexualpädagogik
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/publikationen/sexualkultur" }}>
          Sexualkultur
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
          Sexuelle Gesundheit
          </Breadcrumb.Item>      
      </Breadcrumb>

      <div className="card">
        <h2 className='card-subtitle'>Sexuelle Gesundheit</h2>
        
        <div className="card-thema">
            <ul>
              <li>Sielert, U. / Timmermanns, S.: Zur Lebenssituation schwul-lesbischer Jugendlicher. Eine Expertise im Auftrag des DJI. München 2014 <span><a rel='noopener noreferrer' target='_blank' className='source ' href='https://www.dji.de/fileadmin/user_upload/bibs/Expertise_Sielert_Timmermanns_komplett.pdf'>https://www.dji.de/fileadmin/user_upload/bibs/Expertise_Sielert_Timmermanns_komplett.pdf
              </a></span></li>
              <li>Wirtz, Angelika /Brockmeyer, Norbert / Sielert, Uwe (2022): Sexuelle Gesumndheit juinger Menschen in prekären Lebenssituationen: Ein Modellvorhaben zur Gesundheitsförderung und Prävention. In: Böhm, Maika / Kopitzke, Elisa / Herrath, Frank /Sielert, Uwe (2022): Praxishandbuch Sexuelle Bildung im Erwachsenenalter, 2. Auflage. Weinheim/Basel: BeltzJuventa.</li>
              <li>Brockmeyer, Norbert, Miriam Basilowski, Jana Bernhold, Florian Bury, Lukas Chrzanowski, Arne Kayser, Laura Köcher, Britta Köhler, Petra Kolip, Harriet Langanke, Alina Noveski, Uwe Sielert, Jörg Signerski-Krieger, Adriane Skaletz-Rorowski, Theresa Voß, Angelika Wirtz (2021): Abschlussbericht der Expertise zum Modellvorhaben – JUWEL „Sexuelle Gesundheit – Gesundheitsförderung und Primärprävention in Lebenswelten junger Menschen“ </li>
            </ul>
        </div>

      </div>
    </div>
  )
}

export default PSexuelleGesundheit