import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";

const PSozialpadagogik = () => {
  return (
    <div className='p-sexualkultur'>
    <h1 className='section-title title-publikationen'>Publikationen <h2>Sozialpädagogik</h2></h1>
      <Breadcrumb>
      <span className='me-2 fw-bold breadcrumb-section'>Publikationen / </span>
      <Breadcrumb.Item active>Sozialpädagogik</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/publikationen/sexualpadagogik" }}>
          Sexualpädagogik
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/publikationen/sexualkultur" }}>
          Sexualkultur
          </Breadcrumb.Item>
          <Breadcrumb.Item  linkAs={Link} linkProps={{ to: "/publikationen/sexuelle-gesundheit" }}>
          Sexuelle Gesundheit
          </Breadcrumb.Item>      
      </Breadcrumb>
      
      <div className="card">
      <h2 className='card-subtitle'>Sozialpädagogik</h2>
          <div className="card-year fw-bold">Bücher</div>
          <div className="card-thema">
            <ul>
              <li>Sielert, Uwe (1979): Emanzipatorische Jugendarbeit, Schindele-Verlag, Rheinstetten, (2. Aufl.).</li>
              <li>Sielert, Uwe (1978): Die Mitarbeiter in den Jugendverbänden. Eine Situationsanalyse, Juventa-Verlag, München.</li>
              <li>Sielert, Uwe (1984): Vom Protest zur politisierenden Selbsthilfe, Modelle alternativer Jugendhilfe in den Niederlanden, Extrabuch-Verlag, Frankfurt 1984</li>
              <li>Sielert, Uwe (1985): Zwischen Basisbewegung und staatlichem Zugriff - Sozialpädagogik und Sozialarbeit in den Niederlanden, Campus-Verlag, Frankfurt.</li>
              <li>Sielert, Uwe (2010): Praxishandbuch Jugendarbeit. Teil 2: Jungenarbeit. Juventa Weinheim, 3. Auflg.</li>
              <li>Sielert, Uwe, Katrin Jaenicke, Fabian Lamp und Ulrich Selle (2009): Kompetenztraining Pädagogik der Vielfalt, Juventa, Weinheim.</li>
            </ul>
          </div>
          <div className="card-year fw-bold">Beiträge in Sammelbänden </div>
          <div className="card-thema">
            <ul>
              <li>Sielert, Uwe, G. Presting, R. Westphal (1987): Erziehungskonflikte und Beratung - Institutionelle Hilfen für Familien und Jugendliche. Materialien zum 7. Jugendbericht Band 7, darin: Sexualerziehung in der Jugendarbeit, DJI-Verlag, München</li>
              <li>Sielert, Uwe (1991): Dass Jungen nicht zu Tätern werden: Prävention in der Jugendarbeit. In: Sexueller Missbrauch von Kindern und Jugendlichen. Hrsg. Ulrich Büscher u.a.: Westarp Wissenschaften, Essen.</li>
              <li>Sielert, Uwe (1993): Liebe in Kauf nehmen, um Sexualität zu bekommen? In: R. Winter: Stehversuche. Sexuelle Jungensozialisation und männliche Lebensbewältigung durch Sexualität. Tübingen.</li>
              <li>Sielert, Uwe (1995): Themenzentrierte Interaktion nach Ruth Cohn (TZI). In: Buddrus, Volker (Hrsg.): Humanistische Pädagogik. Klinkhardt-Verlag Bad Heilbrunn, S. 249-265.</li>
              <li>Sielert, Uwe (1995): Der wachsenden Kluft zwischen Sachlichkeit und Menschlichkeit entgegenarbeiten: Themenzentrierte Interaktion an der Hochschule. In: Standhardt, Rüdiger und Löhmer, Cornelia (Hrsg.): Lebendiges Lernen in toten Räumen. Zur Verbesserung der Lehre an der Hochschule. Focus-Verlag, Giessen, S. 46-60.</li>
              <li>Sielert, Uwe (1995): Halt suchen auf schwankendem Boden - Jugend und Moral. Ein neugieriger Blick auf Jungen. In: H. Schlottau und K. Waldmann (Hg.): Mehr als Karriere und Konsum. Dokumentation der Akademie Nordelbien Bd. 24.</li>
              <li>Sielert, Uwe (1995): Jungen und sexuelle Identität - Erste Annäherungen an ein widersprüchliches Thema. In: Bundeszentrale für gesundheitliche Aufklärung. Dokumentation der 1. Europäischen Fachtagung zur Sexualaufklärung. Köln.</li>
              <li>Sielert, Uwe; Bültmann, Gabriele; Munding, Reinhold (1995): Geschlechtsspezifische Sexualpädagogik in der außerschulischen Jugendarbeit im Land NRW. In: Ministerium für Arbeit, Gesundheit und Soziales des Landes Nordrhein-Westfalen, 6. Jugendbericht, S. 491 – 553.</li>
              <li>Sielert, Uwe (1996): Die Entdeckung der Männlichkeit als soziales Problem. In: Sturzenhecker, Benedikt (Hrsg.): Leitbild Männlichkeit - Was braucht die Jungenarbeit? Münster.</li>
              <li>Sielert, Uwe (1997): Jungenarbeit - Zeitgeistthema oder Perspektive für die Jugendarbeit? IN: Dokumentation der 2. Fachtagung zur geschlechtsbewussten Jungenarbeit, hrsg.: Landeszentrale für Gesundheitsförderung Rheinland-Pfalz, Mainz.</li>
              <li>Sielert, Uwe (1997): Jugendsexualität und Sexualpädagogik mit Jungen. In: Möller, Kurt (Hrsg.): Nur Macher und Macho? Geschlechtsreflektierte Jungen- und Männerarbeit. Weinheim und München.</li>
              <li>Sielert, Uwe (1997): Prävention: Erfahrungen, Mythen und Möglichkeiten. In: Bank, Volker u. a. (Hrsg.): Komplementäre Bildung - In der Schule - Nach der Schule. Bielefeld, S. 135-147.</li>
              <li>Sielert, Uwe (1997): Körper, Sex und Seele - Was können Männer für ihre Gesundheit tun? In: MännerGesundheit - Dokumentation der Tagungsreihe an der Universität Hamburg. Hrsg.: Behörde für Arbeit, Gesundheit und Soziales. Hamburg.</li>
              <li>Sielert, Uwe 1998): Zielgruppe Jungen. In: Deinelt, Ulrich und Sturzenhecker, Benedikt: Handbuch offene Jugendarbeit. Münster.</li>
              <li>Sielert, Uwe (1999): Halt suchen auf schwankendem Boden: Männliche Sozialisation und Konsequenzen für die geschlechtsbezogene Jugendbildung. In: Scarbath u.a. (Hrsg.): Geschlechter. Zur Kritik und Neubestimmung geschlechtsspezifischer Sozialisationsforschung. Opladen.</li>
              <li>Sielert, Uwe (1999): Beitrag zur Qualitätsentwicklung der Jungenarbeit. Dokumentation des Fachkongresses "Qualität in der Sozialen Arbeit". Hrsg.: Institut für Soziale Arbeit. Münster.</li>
              <li>Sielert, Uwe 2000): Bipolar, biplural oder völlig egal? Vom Wandel des geschlechtsspezifischen Blicks in Sexualität und Gesellschaft. In: Sielert, Uwe und Karlheinz Valtl: Sexualpädagogik lehren: Materialien für die Aus- und Fortbildung. Ein Handbuch. Weinheim.</li>
              <li>Sielert, Uwe (2001): Homosexualität und Pluralisierung der Lebensformen - Impulse für die pädagogische Praxis. In: Vorbild für Vielfalt?! Homosexualität, Pluralisierung der Lebensformen und ihre Bedeutung für die Arbeit mit Kindern und Jugendlichen in der Kirche. Hg.v. Ministerium für Justiz, Frauen, Jugend und Familie des Landes SH. Kiel 2001. S. 30 - 34</li>
              <li>Sielert, Uwe (2007):  Methoden und Arbeitsformen einer sozialen Arbeit mit Jungen und Männern. In: Hollstein, W./Matzner, M. (Hrsg.): Soziale Arbeit mit Jungen und Männern, Reinhardt-Verlag, München.</li>
              <li>Sielert, Uwe (2008): Ohne Angst verschieden sein können und die Kraft der Vielfalt nutzen. In: Miethling/Krieger (Hrsg.): Zum Umgang mit Vielfalt als sportpädagogische Herausforderung, Czwalina-Verlag, Hamburg.</li>
              <li>Sielert, Uwe (2016): Diversity, Heterogenity, Identity – a German Perspective. In: Behrens, Chr. (Ed): Diversity Concepts – Diversity Politics. Verlag Dr. Kovac, Hamburg.</li>
              <li>Sielert, Uwe (2016): The Skill Training ‚Education in Diversity‘ at CAU. In: In: Behrens, Chr. (Ed): Diversity Concepts – Diversity Politics. Verlag Dr. Kovac, Hamburg.</li>
            </ul>
          </div>
          <div className="card-year fw-bold">Aufsätze</div>
          <div className="card-thema">
            <ul>
              <li>Sielert, Uwe, Veelken, Ludgar (1972): Kirchliche Jugendarbeit - Programm und Wirklichkeit, in: deutsche jugend, 2O.Jhg, 11.S.</li>
              <li>Sielert, Uwe (1974): Curriculum Emanzipation?, in: deutsche jugend, 22. Jhg. 8.</li>
              <li>Sielert, Uwe, Jansen, Rolf, Lorentz, Christian (1977): Meckert Mutter am meisten? Eindrücke und erste Ergebnisse einer Fragebogenaktion der EAF auf dem Kirchentag in Berlin, in: Familienpolitische Informationen der EAF, Nr. 7/8 1977, 16. Jhg.</li>
              <li>Sielert, Uwe (1978): Emanzipatorisches Handlungswissen bei Mitarbeitern der Jugendverbände, in: deutsche jugend, 26. Jhg. 8.</li>
              <li>Sielert, Uwe, Keil, Siegfried (1979): Reform der Jugendhilfe: Auf dem Wege in den totalitären Erziehungsstaat oder mehr Mündigkeit für Kinder, Jugendliche und ihre Eltern?, in: Familienpolitische Informationen der EAF, 18.Jhg. Nr. 6/7.</li>
              <li>Zwischen Basisbewegung und staatlichem Zugriff. Basisinitiativen ,Wohlfahrtsverbände und politisch-administratives System in den Niederlanden, in: neue praxis, 1/1987, 5 S.</li>
              <li>Sielert, Uwe (1987): Ehrenamtlichkeit - ein Tagungsbericht, in: neue praxis 5</li>
              <li>Die Vertreibung aus dem Programm traditioneller Männlichkeit - Jungenarbeit heißt nicht einfach, neue Programme zu entwerfen. In: Bundeszentrale für gesundheitliche Aufklärung (Hrsg.): FORUM Sexualaufklärung. 2/3 1996 S. 13-17.</li>
              <li>Sielert, Uwe; Grenz, Wilfried (1996): "Sport alleine bringt's nicht - Evalaution des Projekts "Sport gegen Gewalt" SH. In: pro jugend Nr. 4 1996, S.10-14.</li>
              <li>Sielert, Uwe (1999): Meinungen, Mythen, Modelle: Wider ein vorschnelles Programm zur neuen Männlichkeit. In: Erziehung heute. Heftthema "Vater sein" (Hrsg.): Friedrich Verlag. Seelze.</li>
              <li>Sielert, Uwe (2009): Das Anderssein anerkennen. Was Jungen und Mädchen unterscheidet, was Jungen und Mädchen brauchen. In: Grundschule. Heft 9 2009, S. 10-14.</li>
            </ul>
          </div>

          <div className="card-year fw-bold">Handbuchartikel</div>
          <div className="card-thema">
            <ul>
              <li>Sielert, Uwe (1975): Artikel über "Entfremdung", "Entscheidung" und "Mobilität" sowie Mitarbeit im Redaktionskollektiv des Handbuchs: Familien- und Lebensberatung, hg.von S.Keil, Stuttgart.</li>
              <li>Sielert, Uwe (1989): Stichworte Vergleichende Sozialpädagogik, Sozialpädagogik in Nigeria, Sozialpädagogik in der Türkei, Sozialpädagogik in den Niederlanden, Sozialpädagogik in der DDR, Jugendarbeit und Professionalisierung in: Kleine Enzyklopädie der Sonderpädagogik, Heilpädagogik und ihrer Nachbargebiete, hg. von G.Dupuis und W.Kerkhoff, Volker Spiess GmbH.</li>
              <li>Sielert, Uwe (1989): „Pädagogische Arbeitsformen in den Jugendverbänden“ und „Sexualität und Sexualerziehung in den Jugendverbänden“, in: Handbuch Jugendverbände, hg. von L.Böhnisch u.a., Weinheim.</li>
              <li>Sielert, Uwe (1991): Zur Entwicklung der pädagogischen Arbeitsformen In: Rauschenbach u. a. (Hg.): Handbuch der Jugendverbände. München, S. 5OO - 512.</li>
              <li>Sielert, Uwe (1999): Erziehung, geschlechtsspezifische. In: Reinhold, Pollak, Heim: Pädagogik-Lexikon. Oldenbourg.</li>
            </ul>
          </div>
      </div>


    </div>
  )
}

export default PSozialpadagogik