import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";

const PSexualpadagogik = () => {
  return (
    <div className='p-sexualkultur'>
    <h1 className='section-title title-publikationen'>Publikationen <h2>Sexualpädagogik</h2></h1>
      <Breadcrumb>
      <span className='me-2 fw-bold breadcrumb-section'>Publikationen / </span>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/publikationen/sozialpadagogik" }}>Sozialpädagogik</Breadcrumb.Item>
          <Breadcrumb.Item  active >
          Sexualpädagogik
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/publikationen/sexualkultur" }}>
          Sexualkultur
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/publikationen/sexuelle-gesundheit" }}>
          Sexuelle Gesundheit
          </Breadcrumb.Item>      
      </Breadcrumb>

      <div className="card">
        <h2 className='card-subtitle'>Sexualpädagogogik</h2>
        
        <div className="card-year fw-bold">Bücher</div>
        <div className="card-thema">
            <ul>
              <li>Sielert, Uwe; Marburger, Helga (1980): Sexualerziehung in der Jugendarbeit.  Diesterweg/Sauerländer - Verlag, Frankfurt</li>
              <li>Sielert, Uwe; Marburger, Helga (1988): Sexualerziehung in der Jugendhilfe: Kindergarten, Heim und Jugendarbeit. Praxismaterialien für die Jugendhilfe und Sozialarbeit, Luchterhand-Verlag, Neuwied.</li>
              <li>Sielert, Uwe; Herrath, Frank; Will Mc Bride (1988): Zeig mal mehr! Ein Bilder- und Aufklärungsbuch über Sexualität. Beltz, Weinheim.</li>
              <li>Sielert, Uwe und Herrath, Frank (Hg): Jugendsexualität zwischen Lust und Gewalt. 28O S., Peter Hammer Verlag, Wuppertal 1990</li>
              <li>Sielert Uwe (1992): Sexualerziehung - Konzeption und didaktische Hilfen für die Aus- und Fortbildung. 186 S., Beltz-Verlag, Weinheim.</li>
              <li>Sielert, Uwe; Keil, Siegfried (Hrsg.) (1993): Sexualpädagogische Materialien für die Jugendarbeit in Freizeit und Schule. Beltz-Weinheim.</li>
              <li>Sielert, Uwe; Valtl, Karlheinz (Hrsg.) (2000): Sexualpädagogik lehren: Didaktische Grundlagen und Materialien für die Aus- und Fortbildung. Weinheim.</li>
              <li>Timmermanns, Stefan; Tuider, Elisabeth; Sielert, Uwe (Hrsg.) (2003): Sexualpädagogik weiter denken. Postmoderne Entgrenzungen und pädagogische Orientierungsversuche. Juventa, Weinheim.</li>
              <li>Sielert, Uwe; Fritz, Jürgen (2009): E-Learning und Sexualpädagogik. Grundlagen und Konzeptideen. Forschung und Praxis der Sexualaufklärung und Familienplanung. Expertise. BZgA, Köln.</li>
              <li>Sielert, Uwe; Schmidt, Renate-Berenike (Hrsg.) (2012): Lehrbuch “Sexualpädagogik” Bildungsverlag EINS, Köln.</li>
              <li>Sielert, Uwe (2015): Einführung in die Sexualpädagogik. Beltz, Weinheim, 3. Erweiterte Auflage.</li>
              <li>Sielert, Uwe; Schmidt, Renate-Berenike (Hrsg.) (2013): Handbuch Sexualpädagogik und Sexuelle Bildung, Juventa, Weinheim, 2. erweiterte Auflage.</li>
              <li>Sielert, Uwe; Schmidt, Renate-Berenike; Henningsen, Anja (Hrsg.) (2017): Gelebte Geschichte der Sexualpädagogik. Weinheim: BELTZJuventa</li>
              <li>Böhm, Maika; Herrath, Frank; Kopitzke,Elisa;  Sielert, Uwe (Hrsg.)(2022) 2. Aufl.: Praxishandbuch Sexuelle Bildung Erwachsener. Weinheim/Basel: BELTZJuventa.</li>
              <li>Henningsen, Anja; Sielert, Uwe (Hrsg.) (2022): Praxishandbuch Sexuelle Bildung, Prävention sexualisierter Gewalt und Antidiskriminierungsarbeit: wertvoll – divers – inklusiv. Weinheim/Basel: BELTZJuventa.</li>
            </ul>
        </div>
        <div className="card-year fw-bold">Beiträge in Sammelbänden</div>
        <div className="card-thema">
          <ul>
            <li>Sielert, Uwe (1981): Ausbildung im außerschulischen Erziehungs- und Sozialwesen in den Niederlanden, in: Studienreform und Handlungskompetenz im außerschulischen Erziehungs- und Sozialwesen, hg. von S. Keil u.a., Luchterhand-Verlag, Neuwied.</li>
            <li>Sielert, Uwe, Möller, Ursula (1986): Jugend und soziale Ungleichheit in den 8Oer Jahren, in: Sozialenquete "Ungleichheit und neue soziale Probleme der achtziger Jahre", hg. von W.Kruse u.a., Westdeutscher Verlag Opladen.</li>
            <li>Sielert, Uwe (1986): Sexualität und Sexualerziehung in der Jugendarbeit, in: Jugendsexualität, Loccumer Protokolle 55.</li>
            <li>Sielert, Uwe (1987): Beziehungslernen und Bildungsverhalten Jugendlicher heute, in: "Es ist nicht gut, daß der Mensch allein sei.." Loccumer Protokolle 56.</li>
            <li>Sielert, Uwe (1987): Situation und neue Akzente außerschulischer Sexualerziehung in der Bundesrepublik Deutschland, in: praktische sexualmedizin, ausgewählte Vorträge vom 8. Weltkongreß für Sexologie in Heidelberg, hg. von W. Eicher u.a..</li>
            <li>Sielert, Uwe; Herrath, Frank (1988): Sexualpädagogische Arbeitshilfen für die Jugendarbeit - Ein Projektbericht, in: Medien als Sexualaufklärer, hg. von N. Kluge, dipa-Verlag, Frankfurt a. M.</li>
            <li>Sielert, Uwe (1988): Der Liebe auf der Spur" - Begleitbuch zur gleichnamigen Spielfilmserie zur Sexualaufklärung für Jugendliche, darin: Spielfolge 6: Ich dachte, wir wären alt genug. Hg. von N.Kluge, Bundeszentrale für gesundheitliche Aufklärung, Köln.</li>
            <li>Sielert, Uwe (1990): Besinnung auf Moralität als Verhaltensprinzip: Jugend und Sexualmoral, in: B. Müller und H.Thirsch (Hg.), Gerechtigkeit und Selbstverwirklichung, Lambertus, Freiburg i.Br.</li>
            <li>Sielert, Uwe (1991): Wann wird ein Mann ein Mann? und: Ein Indianer kennt keinen Schmerz, In: Mann in der Kirche, Oktober und Dezember 1991.</li>
            <li>Sielert, Uwe (1991): Moralität und pluralistische Gesellschaft, in: Sexualität im Wertepluralismus - Perspektiven zur Überwindung der Krise der ethischen Bildung, h.g. v. H.G. Ziebertz, Mainz.</li>
            <li>Sielert, Uwe (1995): Jungen und sexuelle Identität - Erste Annäherungen an ein widersprüchliches Thema. In: Bundeszentrale für gesundheitliche Aufklärung. Dokumentation der 1. Europäischen Fachtagung zur Sexualaufklärung. Köln.</li>
            <li>Sielert, Uwe (1995): Homosexualität und Sexualpädagogik . In: Dimpker, Susanne (Hrsg.): Sexualethische Konkretionen, Marburg.</li>
            <li>Sielert, Uwe, Bültmann, Gabriele, Munding, Reinhold (1995): Geschlechtsspezifische Sexualpädagogik in der außerschulischen Jugendarbeit im Land NRW. In: Ministerium für Arbeit, Gesundheit und Soziales des Landes Nordrhein-Westfalen, 6. Jugendbericht, S. 491 – 553.</li>
            <li>Sielert, Uwe (1997): Was hält die Sexualpädagogik von der Sexualwissenschaft? In: Deutsche Gesellschaft für Sexualwissenschaft (Hrsg.): Leipziger Texte zur Sexualität. Merseburg. Heft 9, S.31-47</li>
            <li>Sielert, Uwe (2002): Sexualerziehung - Sexualberatung - Schwangerschaftskonfliktberatung. In: Chassé, Karl August, von Wensierski, Hans-Jürgen: Praxisfelder der Sozialen Arbeit. Juventa-Verlag, Weinheim, S. 341 – 352.</li>
            <li>Sielert, Uwe (2000): Zwei-Väter- und Zwei-Mütter-Familien. Sorgerecht, Adoption und artifizielle Insemination bei gleichgeschlechtlichen Elternteilen. In: Keil, Siegfried und Haspel, Michael (Hg.): Gleichgeschlechtliche Lebensgemeinschaften in sozialethischer Perspektive. Neukirchen-Vluyn, S. 45 - 64.</li>
            <li>Sielert, Uwe (2010): Sexuelle Verwahrlosung – Interventionsnotwendigkeiten und 
            –möglichkeiten aus pädagogischer Perspektive. In: Schetsche, M. / Schmidt, R. – B. (Hrsg.): Sexuelle Verwahrlosung. Empirische Befunde – gesellschaftliche Diskurse – sozialethische Reflexionen. VS-Verlag, Wiesbaden.</li>
            <li>Sielert, Uwe (2011): Qualitätsentwicklung der Sexualpädagogik. In: Griese, Chr. / Marburger, H.: Bildungsmanagement. De Gryter, Oldenbourg, München.</li>
            <li>Sielert, Uwe; Schmidt, Renate-Berenike (2012): Körperkontakte in pädagogischen Kontexten. In: Schmidt, R.-B., Schetsche, M. (Hrsg.): Körperkontakt. Interdisziplinäre Erkundungen. Psychosoziale Verlag, Gießen.</li>
            <li>Sielert, Uwe; Mahnke, Elke (2012): Körperkontakt im Alter. In:  Schmidt, R.-B., Schetsche, M. (Hrsg.): Körperkontakt. Interdisziplinäre Erkundungen. Psychosoziale Verlag, Gießen.</li>
            <li>Sielert, Uwe (2012): Sexual- und Erziehungswissenschaft. In: Thole, W. u.a.: Sexualisierte Gewalt, Macht und Pädagogik. Buderich, Opladen.</li>
            <li>Sielert, Uwe (2013): Sexualpädagogik und Sexuelle Bildung. In: Briken, P. /Berner, M.: Praxisbuch Sexuelle Störungen. Thieme Verlag  Stuttgart.</li>
            <li>Sielert, Uwe (2015): Sexuelle Vielfalt als Thema der Sexualpädagogik. In: Huch, S. / Lücke, M. (Hrsg.): Sexuelle Vielfalt im Handlungsfeld Schule. Konzepte aus Erziehungswissenschaft und Fachdidaktik. Transcript, Bielefeld.</li>
            <li>Sielert, Uwe (2016): Didaktik der Sexualpädagogik: historische und systematische Kontroversen. In: Henningsen, A. / Tuider, E. / Timmermanns S. (Hrsg.): Sexualpädagogik kontrovers. BELTZ/Juventa Weinheim.</li>
            <li>Sielert, Uwe; Henningsen, Anja (2016):  Evaluation sexueller Bildung. In: Griese, Chr. / Marburger, H. (Hrsg.): Bildungs- und Bildungsorganisationsevaluation. De Gryter Oldenburg. </li>
            <li>Sielert, Uwe (2017): Sprache, intime Kommunikation und Sexualkultur in der Sozialen Arbeit. In: Klein, A. / Tuider, E.: Sexualität und Soziale Arbeit., Hohengehren: Schneider Verlag.</li>
            <li>Sielert, Uwe (2021): Wie ich als Erziehungswissenschaftler Sexualpädagogik konzipiere und was ich mit von der Sexualwissenschaft wünsche. In:  Voß, Heinz-Jürgen; Die deutschsprachige Sexualwissenschaft. Bestandsaufnahme und Ausblick. Gießen: Psychosozial-Verlag, S. 473-499. </li>
            <li>Sielert, Uwe (2022): Sexuelle Bildung im Erwachsenenalter – Eine Reisebeschreibung. In: Böhm, Maika; Kopitzke, Elisa; Herrath, Frank; Sielert, Uwe (Hrsg.): Praxishandbuch Sexuelle Bildung im Erwachsenenalter. Weinheim/Basel: BELTZ/JUVENTA</li>
            <li>Sielert, Uwe (2022): Spielräume für sexuelle Selbstbestimmung? Wie Sexualität das Leben und unser Leben die Sexualität bestimmt. In: Böhm, Maika; Kopitzke, Elisa; Herrath, Frank; Sielert, Uwe (Hrsg.): Praxishandbuch Sexuelle Bildung im Erwachsenenalter. Weinheim/Basel: BELTZ/JUVENTA</li>
          </ul>
        </div>

        <div className="card-year fw-bold">Aufsätze in Zeitschriften</div>
        <div className="card-thema">
          <ul>
            <li>Sielert, Uwe (1987): Jugendsexualität und Sexualerziehung heute, in: deutsche jugend, 7/8.</li>
            <li>Sielert, Uwe; Wendel, Heidrun (1987): Gratwanderung - Modellprojekt "Entwicklung und Erprobung sexualpädagogischer Arbeitshilfen für die Jugendarbeit", in: pro familia magazin Heft 1/1987</li>
            <li>Sielert, Uwe; Herrath, Frank 1987): Überlegungen zur Aids-Aufklärung in der sexualpädagogischen Arbeit mit Jugendlichen, in: pro familia magazin 4/1987</li>
            <li>Sielert, Uwe (1989): Lebendiges Lernen und lebendige Sexualität, in: Themenzentrierte Interaktion, 3. Jhg. 1/1989.</li>
            <li>Sielert, Uwe (1995): Die erotischen Gravitationsverhältnisse im pädagogischen Alltag. In: Der pädagogische Blick. 2/1995, S. 79 - 89.</li>
            <li>Jungensexualität - Mädchensexualität. In: Pro Jugend, 3/1993, S. 5 – 10</li>
            <li>Sielert, Uwe (2008): Sexualpädagogik in Deutschland. In: Bundesgesundheitsblatt 2008</li>
            <li>Sielert, Uwe (2011): Sexuality Education in Germany. In: BZgA: Sexuality education international, FORUM Sexualaufklärung und Familienplanung Nr.2.</li>
            <li>Sielert, Uwe (2012): Paradigmenwechsel der Sexualpädagogik im Kontext gesellschaftlicher Entwicklungen. In: Außerschulische Bildung, Heft 3 2012, S.258-267.</li>
            <li>Sielert, Uwe (2013): Sexualaufklärung, Sexualpädagogik und sexuelle Bildung in Deutschland. Begriffe, Konzepte und gesellschaftliche Realitäten. In: Sexuologie. Heft 3-4, 2013, S. 117-123.</li>
            <li>Sielert, Uwe (2014): Erziehungswissenschaftliche Reflexionen zum Pornografiediskurs. In: unsere jugend. H. 2, 2014. S.50-61.</li>
            <li>Sielert, Uwe (2015): Vom Repressionsdiskurs zur sexuellen Bildung. In: Sozialmagazin 40. Jg. H. 1-2 , 2015. S. 6-15</li>
            <li>Sielert, Uwe (2015): Sexualerziehung und sexuelle Selbstbildung. In: sozialpädagogische impulse. 4/2015. S. 12-16  </li>
            <li>Sielert, Uwe (2015): Kinder konstruieren ihre eigene sexuelle Identität. Empirische Befunde und deren sexualpädagogische Konsequenzen. In: Grundschule. Heft 3 2015, S.16-23.</li>
            <li>Sielert, Uwe (2016): Vom Repressions- zum Befreiungsdiskurs: Emanzipatorische Perspektiven in der Sexualpädagogik. In:  pro familia magazin N. 4 /2016, Frankfurt a. N., S. 22 – 26</li>
            <li>Sielert, Uwe 2016): Sexualität ist politisch. In:  Forum Gemeindepsychologie, Jg. 21 (2016), Ausgabe 1</li>
            <li>Sielert, Uwe (2017): Sexuelle Bildung als zentraler Ansatz (auch) zum Schutz von Kindern und Jugendlichen. In: Unsere Jugend 11+12, München, Basel: Reinhardt Verlag, 2017. 464 – 472.</li>
            <li>Sielert, Uwe 2018): „Prävention vor sexueller Gewalterfahrung darf keine Sexualprävention werden!“ In: tv diskurs 84 2/2018/ 22. Jg. S. 27 – 31.</li>
          </ul>
        </div>

        <div className="card-year fw-bold">Lexikon- Handbuchartikel und Online-Glossare</div>
        <div className="card-thema">
          <ul>
            <li>Sielert, Uwe; Marburger, Helga (1984): "Sexualerziehung in der Jugendarbeit", in: Handbuch der Sexualpädagogik, hg. von N. Kluge, Düsseldorf 1984</li>
            <li>Artikel über "Zärtlichkeit" und "Heterosexualität" im Lexikon der Sexualität, hg. v. S. R. Dunde, Deutscher Studienverlag, Weinheim, 1991</li>
            <li>Artikel über "Sexualberatung mit Jugendlichen zum Thema AIDS", in: Beratungsführer zu AIDS, hg. v. S. R. Dunde, Hippokrates Verlag, Stuttgart, 1991</li>
            <li>Sexualpädagogik. Stichwort im Lexikon für Theologie und Kirche. 1997</li>
            <li>Geschlechtsspezifische Erziehung sowie Sexualerziehung und Sexualpädagogik. Stichworte im Pädagogik-Lexikon hg. v. Reinhold, Pollak und Heim. Oldenbourg-Verlag, München 1999. S. 231 – 235</li>
            <li>Sielert, Uwe (2006): Sexualberatung. In: Steinebach, Chr. (Hrsg.): Handbuch psychologischer Beratung, Klett-Cotta, Stuttgart.</li>
            <li>Sielert, Uwe (2009): Kentler, Helmut. In: Sigusch, V. /Grau, G. (Hrsg.): Personenlexikon der Sexualforschung. Campus, Frankfurt, S. 334-338</li>
            <li>Sielert, Uwe (2009):  Sexualpädagogik. In: Brinkmann, W.: Differenzielle Pädagogik. Auer-Verlag, Donauwörth.</li>
            <li>Sielert, Uwe (2015): Sexualerziehung. In: Feuerhelm, W. (Hrsg.): Taschenlexikon der Sozialarbeit und Sozialpädagogik. Quelle und Meyer Verlag, Wiebelsheim, S. 530f.</li>
            <li>Sielert, Uwe (2015): Sexualerziehung / Sexualpädagogik. In: Thole, W. u.a. (Hrsg.): Taschenwörterbuch Soziale Arbeit., utb Klinghardt, Bad Heilbrunn</li>
            <li>Sielert, Uwe (2018): Sexualpädagogik. In: Otto, H.U. / Thiersch, H. / Treptow, R. /Ziegler, H.: Handbuch Soziale Arbeit. Grundlagen der Sozialarbeit und Sozialpädagogik. 6. Auflage. München: Reinhardt Verlag.</li>
            <li>Sielert, Uwe (2018): Sexualpädagogisch-geschichtliche Perspektiven auf die Auseinandersetzung mit sexualisierter Gewalt. In: Retkowski, A./ Treibel, /    Tuider; E. (Hrsg.): Handbuch Sexualisierte Gewalt und pädagogische Kontexte. Weinheim: BELTZJuventa. S. 60-70. </li>
            <li>Sielert, Uwe (2018): Sexualpädagogik. Gender Glossar. Universität Leipzig.</li>
            <li>Sielert, Uwe (2021): Sexualerziehung. In: Görres-Gesellschaft (Hrsg.): Staatslexikon Recht – Wirtschaft – Gesellschaft. Freiburg/Basel/Wien: Herder.  </li>
          </ul>
        </div>

        <div className="card-year fw-bold">Unveröffentlichte Forschungsberichte</div>
        <div className="card-thema">
          <ul>
            <li>Sielert, Uwe; Philipps, Ina (1994): Sexualpädagogische Aus- und Fortbildung in der Bundesrepublik Deutschland. Expertise zus. mit I. Philipps. I.A. der Bundeszentrale für gesundheitliche Aufklärung. Köln</li>
            <li>Sielert, Uwe, Osbar, Christian und Specht, Ralf (1996): Abschlussbericht des Modellprojekts "Situationsanalyse zur Sexualpädagogik in den Fachschulen für Sozialpädagogik und Berufsfachschulen in Schleswig-Holstein. Kiel.</li>
            <li>Sielert, Uwe, Grenz, Wilfried und Bischoff, Sandra (1998): Situationsanalyse "Gleichgeschlechtliche Lebensformen in Schleswig-Holstein". Ministerium für Jugend, Familie Frauen des Landes Schleswig-Holstein, Kiel</li>
            <li>Sielert, Uwe (1996): Abschlussbericht Berufsfeldanalyse der Einrichtungen des Landesverbandes PRO FAMILIA Schleswig-Holstein Kiel</li>
            <li>Sielert, Uwe, Herrath, Frank (1998): Abschluss- und Evaluationsbericht des BLK-Modellprojekts "Sexualpädagogik in der Hochschulausbildung - Entwicklung und Erprobung eines interdisziplinären Curriculums für wissenschaftliche Hochschulen, Kiel 1998</li>
          </ul>
        </div>

        <div className="card-year fw-bold">Medien</div>
        <div className="card-thema">
          <ul>
            <li>Sielert, Uwe (1989): "Liebe... Über den Umgang mit Liebe, Sexualität und Schwangerschaft". Köln 1989 Eine Aufklärungsbroschüre für Jugendliche ab 16 Jahren. Erstellt im Auftrag der Bundeszentrale für gesundheitliche Aufklärung in Köln </li>
            <li>Sielert, Uwe; Frank Herrath (1991): Lisa und Jan - Ein Aufklärungsbuch für Kinder und ihre Eltern. Beltz - Verlag </li>
            <li>Sielert, Uwe; Karin Desirat; Frank Herrath (1992): Sex-Lust und Leben. Begleitbuch zur Video-Reihe. Atlas-Film</li>
            <li>Sielert, Uwe; Frank Herrath; Pim Richter; Christa Wanzeck-Sielert (1994): ,Sechs mal Sex und mehr...Das Begleitbuch zur Fernseh-Serie. Beltz-Verlag, Weinheim.</li>
            <li>Sielert, Uwe (1993): Unser Kind fällt aus der Rolle. Eine Aufklärungsborschüre für Eltern von Kindern in sexuellen Orientierungskonflikten. Köln.</li>
            <li>Sielert, Uwe; Philipps, Ina (1995): Sexualpädagogische Aus- und Fortbildung in der Bundesrepublik Deutschland. Expertise und Wegweiser. Hrsg.: Bundeszentrale für gesundheitliche Aufklärung Köln.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PSexualpadagogik