import image from '../images/photo-home.png'

const Home = () => {
  return (
    <section className="homepage">

        <div className="hero-section">
            <div className="hero-section-description">
                <h1 className='hero-section-title'>Uwe Sielert</h1>
                <h2 className='hero-section-subtitle hyphenate .hyphens '>Erziehungswissenschaftler und Sexualpädagoge</h2>
            </div>
            <div className="hero-section-image-wrapper">
                <img className='hero-section-image' src={image} alt="Uwe Sielert" />
            </div> 
        </div>  
    </section>
  )
}

export default Home