import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";

const PSexualkultur = () => {
  return (
    <div className='p-sexualkultur'>
    <h1 className='section-title title-publikationen'>Publikationen <h2>Sexualkultur</h2></h1>
      <Breadcrumb>
      <span className='me-2 fw-bold breadcrumb-section'>Publikationen / </span>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/publikationen/sozialpadagogik" }}>Sozialpädagogik</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/publikationen/sexualpadagogik" }}>
          Sexualpädagogik
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
          Sexualkultur
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/publikationen/sexuelle-gesundheit" }}>
          Sexuelle Gesundheit
          </Breadcrumb.Item>
      </Breadcrumb>

      <div className="card">
        <h2 className='card-subtitle'>Sexualkultur</h2>
        
        <div className="card-thema">
            <ul>
              <li>Sielert, Uwe (2001): Gender Mainstreaming im Kontext einer Sexualpädagogik der Vielfalt. Forum Sexualaufklärung und Familienplanung 4/2001 Hg. v. Bundeszentrale gesundheitlicher Aufklärung, Köln.</li>
              <li>Sielert, Uwe (2010): Mädchenkörper, Jungenkörper, Lehrkörper. Vom Umgang mit Nähe und Distanz, Macht und Erotik in pädagogischen Beziehungen. In: Lernende schule. Heft 51 2010, S. 16-21.</li>
              <li>Sielert, Uwe (2011): Kontrolle allein reicht nicht. In: FORUM sozial. Heft 3 2011, S. 29-33.</li>
              <li>Sielert, Uwe (2011): Sexualkulturbildung und Gewaltprävention in der sozialen Arbeit. In: soziale Passagen. Heft 2/2011, S. 253-267. Ders. Titel in: Sexuologie. Heft 2-4 2.11, S. 128-135.</li>
              <li>Sielert, Uwe (2014): Sexualerziehung, sexuelle Bildung und Entwicklung von Sexualkultur als sozialpädagogische Herausforderung. In: Sozialmagazin 39. Jg. H. 1-2 2014, S. 38-46. </li>
              <li>Sielert, Uwe (2014): Gelingensbedingungen lustvoller und produktiver Sexualkultur in pädagogischen Organisationen. In: Geramanis, O. / Hermann, K. (Hrsg.): Organisation und Intimität. Der Umgang mit Nähe im organisationalen Alltag – zwischen Vertrauensbildung und Manipulation.  Carl-Auer-Verlag. Heidelberg.</li>
              <li>Sielert, Uwe (2014): Sexuelle Bildung statt Gewaltprävention. In: Böllert, K. / Watzlawik, M.: Sexualisierte Gewalt. Institutionelle und Professionelle Herausforderungen. Wiesbaden: Springer Verlag, S. 111-126.</li>
              <li>Sielert, Uwe, Marburger, Helga, Griese, Christine (2017): Sexualität und Gender im Einwanderungsland. Öffentliche und zivilgesellschaftliche Aufgaben – Ein Lehr- und Praxisbuch. Oldenbourg: De Gruyter.</li>
              <li>Dabrock, Peter, Augstein, Renate, Helferich, Cornelia, Schardien, Stefanie, Sielert, Uwe (2015): Unverschämt schön. Sexualethik: evangelisch und lebensnah. Gütersloher Verlagshaus.</li>
              <li>Sielert, Uwe (2022): Sexualkultur in Institutionen der Sozialen Arbeit gestalten. In: Höblich, Davina; Mantey, Dominik (Hrsg.): Handbuch Sexualität und Soziale Arbeit. Weinheim/Basel: BELTZJuventa.</li>
              <li>Sielert, Uwe; Waniliek, Reiner (2021): Arbeitshilfe zur Gestaltung von Sexualkultur in Organisationen. Hrsg.: LVR-Landesjugendamt Rheinland. <span><a rel='noopener noreferrer' target='_blank' className='source ' href='https://www.lvr.de/media/wwwlvrde/jugend/service/arbeitshilfen/Arbeitshilfe_Sexualkultur_in_Organisationen_LVR_November_2021.pdf'>https://www.lvr.de/media/wwwlvrde/jugend/service/arbeitshilfen/Arbeitshilfe_Sexualkultur_in_Organisationen_LVR_November_2021.pdf
              </a></span></li>
            </ul>
        </div>
      </div>  

    </div>
  )
}

export default PSexualkultur