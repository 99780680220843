import logo from '../images/logo.png'
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
  return (

    <Navbar className='nav' collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container fluid id='container'>
        <Navbar.Brand href="#home">
            <Link to='/'>
                <img src={logo} alt="logo" className='img-logo'/>
            </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">

                <NavDropdown title="UWE SIELERT" id="collasible-nav-dropdown">
                    <LinkContainer to='/ausbildung'>
                        <NavDropdown.Item>Ausbildung</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/berufstatigkeit'>
                        <NavDropdown.Item>Berufstätigkeit</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/mitgliedschaften'>
                        <NavDropdown.Item>Mitgliedschaften</NavDropdown.Item>
                    </LinkContainer>
                </NavDropdown>

                <LinkContainer to='/aktuelles'>
                    <Nav.Link>AKTUELLES</Nav.Link>
                </LinkContainer>

                <NavDropdown title="ARBEITS- UND FORSCHUNGSFELDER" id="collasible-nav-dropdown">
                    <LinkContainer to='/arbeits-und-forschungsfelder/sozialpadagogik'>
                        <NavDropdown.Item>Sozialpädagogik</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/arbeits-und-forschungsfelder/sexualpadagogik'>
                        <NavDropdown.Item>Sexualpädagogik</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/arbeits-und-forschungsfelder/sexualkultur'>
                        <NavDropdown.Item>Sexualkultur</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/arbeits-und-forschungsfelder/sexuelle-gesundheit'>
                        <NavDropdown.Item>Sexuelle Gesundheit</NavDropdown.Item>
                    </LinkContainer>
                </NavDropdown>

                <NavDropdown title="PUBLIKATIONEN" id="collasible-nav-dropdown">
                    <LinkContainer to='/publikationen/sozialpadagogik'>
                        <NavDropdown.Item>Sozialpädagogik</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/publikationen/sexualpadagogik'>
                        <NavDropdown.Item>Sexualpädagogik</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/publikationen/sexualkultur'>
                        <NavDropdown.Item>Sexualkultur</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/publikationen/sexuelle-gesundheit'>
                        <NavDropdown.Item>Sexuelle Gesundheit</NavDropdown.Item>
                    </LinkContainer>
                </NavDropdown>

                <LinkContainer to='/rezensionen'>
                    <Nav.Link>REZENSIONEN</Nav.Link>
                </LinkContainer>

                <LinkContainer  to='/kontakt'>
                    <Nav.Link id='last' className='lg-mb-3'>KONTAKT</Nav.Link>
                </LinkContainer>
                
            </Nav>
        </Navbar.Collapse>
    </Container>
    </Navbar>
   
  )
}

export default Header