import image from '../images/photo-kontakt.png'

const Kontakt = () => {
  return (
    <section className='kontakt'>

      <div className="kontakt-section">
            <div className="kontakt-section-description">
              <h1 className='kontakt-section-title'>Prof. Dr. Uwe Sielert</h1>
              <h2 className='kontakt-section-subtitle'>Erziehungswissenschaftler und Sexualpädagoge</h2>
              <p className='kontakt-section-details'>bis 31.03.2017 Direktor des Instituts für Pädagogik</p> 
              <p className='kontakt-section-details'>der Christian-Albrechts-Universität zu Kiel</p> 
              <p className='kontakt-section-details'>Aktuelle e-mail: <a className='email-link' href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#115;&#105;&#101;&#108;&#101;&#114;&#116;&#64;&#112;&#97;&#101;&#100;&#97;&#103;&#111;&#103;&#105;&#107;&#46;&#117;&#110;&#105;&#45;&#107;&#105;&#101;&#108;&#46;&#100;&#101;">&#115;&#105;&#101;&#108;&#101;&#114;&#116;&#64;&#112;&#97;&#101;&#100;&#97;&#103;&#111;&#103;&#105;&#107;&#46;&#117;&#110;&#105;&#45;&#107;&#105;&#101;&#108;&#46;&#100;&#101;</a></p>
            </div>
            <div className="hero-section-image-wrapper kontakt-wrap">
                <img className='hero-section-image kontakt-image' src={image} alt="Uwe Sielert" />
            </div> 
        </div> 
    </section>
  )
}

export default Kontakt