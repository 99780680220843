import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";

const Berufstatigkeit = () => {
  return (
    <div className='berufstatigkeit'>
      <h1 className='section-title'>Berufstätigkeit</h1>
      <Breadcrumb>
          <span className='me-2 fw-bold breadcrumb-section'>Uwe Sielert / </span>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/ausbildung" }}>
          Ausbildung
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Berufstätigkeit</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/mitgliedschaften" }}>
          Mitgliedschaften
          </Breadcrumb.Item>
        </Breadcrumb>
        
        <div className="card">
          <div className="card-year">Seit 2021 </div>
          <ul className='group-list'>
            <li>
              <span className='fw-bold'>Lehrbeauftragter</span> für Sexualpädagogik an der <span className='fw-bold'>Medical School Hamburg</span>, Fakultät Art, Health and Social Science, Studiengang Sexualwissenschaft
              <p><a rel='noopener noreferrer' target='_blank' className='source' href='https://www.arts-and-social-change.de/master/sexualwissenschaft/'>https://www.arts-and-social-change.de/master/sexualwissenschaft/</a></p>
            </li>
            <li>
              <span className='fw-bold'>Berater</span> im Modellvorhaben des Verbands der privaten Krankenversicherungen e.V., (PKV): „Sexuelle Gesundheit – Gesundheitsförderung und Primärprävention in Lebenswelten junger Menschen (JUWEL) “ Träger: <span className='fw-bold'>Katholisches Klinikum Bochum/Ruhr-Universität Bochum</span>
            </li>
          </ul>
        </div>

        <div className="card">
          <div className="card-year">1992 – 2017</div>
          <div className="card-thema"><span className='fw-bold'>Professor für Pädagogik </span>
            mit dem Schwerpunkt Sozialpädagogik am Institut für Pädagogik der Christian- Albrechts-Universität zu Kiel</div>
        </div>

        <div className="card">
          <div className="card-year">1989 – 1992</div>
          <div className="card-thema"><span className='fw-bold'>Mitarbeiter der Bundeszentrale für gesundheitliche Aufklärung in Köln</span> (BZgA), Fachbehörde im Geschäftsbereich des Bundesministeriums für Jugend und Gesundheit.  Hauptaufgabe: Steuerung der Personalkommunikativen Kampagne zur HIV-Prävention</div>
        </div>

        <div className="card">
          <div className="card-year">1986 – 1989</div>
          <div className="card-thema"><span className='fw-bold'>Vertretung einer Universitätsprofessur</span> (C4) für Sozialpädagogik an der Abteilung 12 der Universität Dortmund</div>
        </div>

        <div className="card">
          <div className="card-year">1979 – 1980</div>
          <div className="card-thema"> Stipendiat des Deutschen Akademischen Austauschdienstes (DAAD) mit <span className='fw-bold'>Gastdozentur an der Freien Universität Amsterdam</span>, Fachgruppe Soziologie der Sozialarbeit</div>
        </div>

        <div className="card">
          <div className="card-year">1974 – 1986</div>
          <div className="card-thema"><span className='fw-bold'>Wissenschaftlicher Assistent für Sozialpädagogik </span>an der Abteilung 12 der Universität Dortmund (Lehr- und Prüfungstätigkeit in den Studiengängen Diplom-Pädagogik und Lehramt Sek II (Berufsfachschule für Sozialpädagogik)
          </div>
        </div>

    </div>
  )
}

export default Berufstatigkeit