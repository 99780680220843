import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";
import book1 from '../../images/auf-sexuellekultur1.png'
import book2 from '../../images/auf-sexuellekultur2.png'

const AUFSexualkultur = () => {
  return (
    <div className='auf-sexualkultur'>
      <h1 className='section-title title-arbeits'>Arbeits- und Forschungsfelder <h2>Sexualkultur</h2></h1>
        <Breadcrumb>
        <span className='me-2 fw-bold breadcrumb-section'>Arbeits- und Forschungsfelder / </span>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sozialpadagogik" }}>Sozialpädagogik</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sexualpadagogik" }}>
          Sexualpädagogik
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
          Sexualkultur
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/arbeits-und-forschungsfelder/sexuelle-gesundheit" }}>
          Sexuelle Gesundheit
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="card new-display">
          <h2 className='card-subtitle'>Sexualkultur</h2>
          <div className="aktuelles-description">Parallel zum Paradigmenwechsel der internationalen Sexualforschung vom essentialistischen bio-medizinischen Modell zur sozialwissenschaftlichen Erforschung diverser Sexualitäten bis zu den Zusammenhängen von Macht, Gender und Begehren des Sexualitätsdispositivs (Foucault) ergab sich die Perspektivenerweiterung von der Sexualerziehung über die Sexuelle Bildung bis zur umfassenden Sexualkulturgestaltung. Nationalkonservative und religiös-dogmatische Kreise sahen den Start dieser international vorbereiteten Diskurserweiterung von der ‚Sex Education‘ zur ‚Sexuality Citizenship‘ repräsentiert in meinem Aufsatz über <span className='fw-bold'>„Gender Mainstreaming, sexual diversity und Sexualpädagogik“</span>, der 2001 in dem offiziellen Fachorgan der BZgA erschienen ist (BZgA 4/2001). Dabei deutete sich bereits Mitte der 1980er Jahre auch in Deutschland an, dass die Prävention der Verbreitung sexuell ansteckender Krankheiten, die Arbeit gegen sexuelle und sexualisierte Gewalt wie auch gegen die Diskriminierung sexueller und geschlechtlicher Identitäten sowie die politische Einhegung der Sexualindustrie nur durch einen strukturell wirksamen Wandel von Sexualkultur bewirkt werden kann. </div>

          <div className="aktuelles-description">Im nationalen AIDS-Beirat und durch die Steuerung der <span className='fw-bold'>Personalkommunikativen Kampagne der BZgA zur HIV-Prävention</span> in Zusammenarbeit mit der AIDS-Hilfe war ich an der gesellschaftlichen Enttabuisierung der Themen <span className='fw-bold'>„Sexualität – sexuelle Gesundheit und Vielfalt der sexuellen Identitäten“</span> beteiligt und konnte auf diese Weise auch gesamtgesellschaftlich Sexualkultur mitgestalten.</div>

          <div className="aktuelles-description">Die <span className='fw-bold'>Skandalisierung des sexuellen Missbrauchs</span> zunächst in Familien, dann zunehmend auch in pädagogischen Organisationen sowie die Mee-too-Debatte verdeutlichte in den letzten Jahrzehnten, dass sexuelle Grenzverletzungen und sexualisierte Gewalt tief in unserer patriarchalen Kultur mit ihren strukturellen Machtverhältnissen verankert sind und nicht nur sexuelle Bildung, sondern eine grundlegende Veränderung von Sexualkultur erforderlich machen. Als Mitglied des Instituts für Sexualpädagogik (isp) und der Gesellschaft für Sexualpädagogik (gsp) war ich mit der <span className='fw-bold'>Aufarbeitung sexueller Grenzverletzungen im sexualpädagogischen Diskursraum</span> befasst und als Erziehungswissenschaftler in zahlreichen nationalen  <span className='fw-bold'>Gremien und Beratungsprozessen beteiligt</span>, die gegen sexualisierte Gewalt eingerichtet wurden (siehe „Mitgliedschaften“). Über meine Pensionierung hinaus hatte ich bis 2019 die <span className='fw-bold'>Leitung des Projekts “Sexualpädagogik und Prävention sexueller Gewalt”</span> – zusammen mit der Juniorprofessorin Anja Henningsen. </div>

          <div className="aktuelles-description">Insbesondere seit 2015 nahm die Zahl der Geflüchteten in Deutschland zu und damit auch die <span className='fw-bold'>Durchmischung der bundesdeutschen Sexualkultur mit Wertvorstellungen und Traditionen aus anderen Kulturkreisen</span>. Der Bedarf an interkultureller Forschung, Beratung und Materialentwicklung betraf alle, die sich mit Sexualverhalten, sexuelle Bildung und Sexualkultur befassten. Zusammen mit der Migrationsexpertin Helga Marburger verfasste ich ein Lehrbuch zur Sexualität und Gender im Einwanderungsland. (Sielert/Marburger/Griese 2017): </div>

          <div className='auf-image-group mt-3'>
            <img className='auf-image' src={book1} alt="arbeitshilfe buch"/>
            <p className='mt-2'><a rel='noopener noreferrer' target='_blank' className='source source-aktuelles' href='https://www.google.com/search?q=Sexualit%C3%A4t+und+Gender+im+Einwanderungsland:+%C3%96ffentliche+und+zivilgesellschaftliche+Aufgaben+%E2%80%93+ein+Lehr-+und+Praxishandbuch&kgmid=/g/11c6qqdwyf&ved=0EOTpBwgAKAAwAA&source=.lens.button&tbm=isch&hl=de&gl=US#imgrc=cQWhmmwrewPGtM'>https://www.google.com/search?q=Sexualit%C3%A4t+und+Gender+im+Einwanderungsland:+%C3%96ffentliche+und+zivilgesellschaftliche+Aufgaben+%E2%80%93+ein+Lehr-+und+Praxishandbuch&kgmid=/g/11c6qqdwyf&ved=0EOTpBwgAKAAwAA&source=.lens.button&tbm=isch&hl=de&gl=US#imgrc=cQWhmmwrewPGtM
            </a></p>
          </div>

          <div className="aktuelles-description">Die Anlässe zur Gestaltung von Sexualkultur lagen und liegen immer noch vornehmlich im Risiko- und Gefahrensektor, obwohl es nach Aussage des Sexualwissenschaftlers Volkmar Sigusch wesentlich effektiver ist, gesamtgesellschaftlich konstruktiv zu einer „ars erotica“ zu streiten, einer Sexualkultur also, die grundsätzlich hilft, die Schattenseiten des Sexuellen zu minimieren. 2012 – 2014 wurde ich vom Rat der Evangelischen Kirche in Deutschland (EKD) in die Ad-hoc-Kommission zur Erarbeitung einer neuen Denkschrift zur Sexualethik berufen, die ihr 2015 ihren Text vorlegte, der allerdings nach Protesten evangelikaler Freikirchen nur den Status einer ‚Beinahe-Denkschrift‘ erreichte (Darbrock u.a. 2015). </div>

        <div className='auf-image-group mt-3'>
          <img className='auf-image me-5' src={book2} alt="arbeitshilfe buch"/>
          <p className='mt-2'><a rel='noopener noreferrer' target='_blank' className='source source-aktuelles' href='https://www.penguinrandomhouse.de/ebook/Unverschaemt-schoen/Peter-Dabrock/Guetersloher-Verlagshaus/e482454.rhd'>https://www.penguinrandomhouse.de/ebook/Unverschaemt-schoen/Peter-Dabrock/Guetersloher-Verlagshaus/e482454.rhd
          </a></p>
        </div>

        <div className="aktuelles-description">Das Ergebnis zeigt, dass Sexualkultur weiterhin ein Politikum ist, ein Politikfeld, um das in demokratischen Gesellschaften gerungen wird. Bisher fehlen jedoch noch die Erfahrung und die Instrumente, mit denen vor allem auf organisationaler Ebene eine humane, an den Menschenrechten orientierte Sexualkultur verfolgt werden kann. Anfänge dazu sind gemacht durch die systematische <span className='fw-bold'>Ergänzung der Sexuellen Bildung durch Organisationsentwicklung</span> (Sielert Sielert/Wanielik 2021 und Sielert 2022).</div>



          </div>

    </div>
  )
}

export default AUFSexualkultur